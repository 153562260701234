import { WidgetConfig, ConfigAttributes } from "./typing";

enum WidgetType {
    MarketIndex = 'dnse-market-index',
}

new (class {
    private script?: HTMLScriptElement;
    private widgetContainer?: HTMLElement;
    private widgetType?: string;
    private config?: Record<string, string | number | Array<string | number>>;

    private commonConfig: WidgetConfig = {
        width: '100%',
        height: '100%',
        theme: 'light',
    };
    private defaultConfigs: Record<WidgetType, ConfigAttributes> = {
        [WidgetType.MarketIndex]: {
            floor_code: 'VNINDEX',
        },
    };

    constructor() {
        this._processWidget();
        this._renderWidget();
    }

    _processWidget() {
        this.script = document.currentScript as HTMLScriptElement;
        this.widgetContainer = this.script.parentElement as HTMLElement;
        this.widgetType = this.widgetContainer.className.split(' ')[0];
        this.config = this._scriptConfigToJSON();
    }

    _scriptConfigToJSON() {
        const config =
            this.script?.innerHTML
                .replace(/ /g, '')
                .replace(/(\r\n|\n|\r)/gm, '')
                .trim() || '';

        try {
            return JSON.parse(config);
        } catch (error) {
            console.error('Widget settings parse error: ' + error);
            return {};
        }
    }

    _renderWidget() {
        switch (this.widgetType) {
            case WidgetType.MarketIndex:
                this.marketIndexEmbedding();
                break;
            default:
                console.log('Unknown widget type: ' + this.widgetType);
                break;
        }
    }

    parseUnitValue(value: string | number, unit: string) {
        return typeof value === 'string' ? value : value + unit;
    }

    serializedQueryString = function (obj: Record<string, string | number>) {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        return str.join('&');
    };

    marketIndexEmbedding() {
        const container = this.widgetContainer as HTMLElement;
        const script = this.script as HTMLScriptElement;
        const config = {
            ...this.commonConfig,
            ...this.defaultConfigs[WidgetType.MarketIndex],
            ...this.config,
            utm_source: window.location.hostname || '',
            utm_medium: 'widget',
        };

        //remove Width and Height from query string
        const queryString = this.serializedQueryString(
            (({ width, height, ...otherProps }) => otherProps)(config),
        );

        const fontLink = this.renderFontLinkTag();
        const iframe = this.renderIframe(queryString);

        document.head.appendChild(fontLink);

        container.style.cssText = `
      width: ${this.parseUnitValue(config.width, 'px')};
      height: ${this.parseUnitValue(config.height, 'px')};
      box-shadow: 0 1px 8px -2px rgba(121, 121, 121, 0.15);
    `;

        container.append(iframe);
        container.removeChild(script);
    }

    renderFontLinkTag(): HTMLLinkElement {
        let fontLink = document.createElement('link');
        fontLink.rel = 'stylesheet';
        fontLink.href = process.env.WIDGET_FONT || '';

        return fontLink;
    }

    renderIframe(queryString: string): HTMLIFrameElement {
        const iframe: HTMLIFrameElement = document.createElement('iframe');
        iframe.setAttribute(
            'src',
            `${process.env.WIDGET_MINI_URL ||'http://localhost:4000/mini-widget'}?${queryString}`,
        );
        iframe.setAttribute('scrolling', 'no');
        iframe.style.cssText = `
      width: 100%;
      height: 100%;
      border: none;
      scrolling: none;
    `;

        return iframe;
    }
})();
